<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputSwitch
        :checked="details.trustees_secondary_required"
        @toggle="save"
      ></InputSwitch>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="'/trustees/primary'"
        v-bind:center="false"
        v-bind:loading="loading"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputSwitch from '@/common/ui/InputSwitch'

export default {
  name: 'TrusteesSecondaryOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputSwitch
  },
  computed: {
    details() {
      return this.$store.getters.details
    },
    partner() {
      return this.$store.getters.partner
    },
    isValidated() {
      return true
    },
    number() {
      return '3'
    },
    heading() {
      return 'Would you like to name secondary trustees?'
    },
    subHeading() {
      return '   You should plan for every eventuality and name people who can step in if the main trustees are unable or un-willing to act.'
    },
    forwardTo() {
      if (this.details.trustees_secondary_required) return '/trustees/secondary'
      return '/trustees/check_and_confirm'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    save() {
      if (this.details) {
        this.$store.commit('details', {
          trustees_secondary_required: !this.details.trustees_secondary_required
        })
      }
    }
  }
}
</script>

<style></style>
